/*
=====================================
    First Image Sequence
=====================================
*/
import {addClass, isMobile}  from './helper.js';

import * as urlsA from "../media/A_Intro_15fps_Q2_2/*.jpg";
import * as urlsB from "../media/B_Closeups_15fps_Q2_4/*.jpg";
import * as urlsD from "../media/C_Boiler_15fps_Q5_1/*.jpg";
import * as urlsE from "../media/C_ShowerHead_Q2_1/*.jpg";
import * as urlsH from "../media/D_Coffee_Drip_15fps_Q2_3/*.jpg";


export class ImgSequences {
    constructor(app){
        this.app = app;
        this.preloadStep = 16;
        this.sequences = {
            sequnceA: {
                id: 'sequnceA',
                canvas: document.getElementById('jpg-seq-a-canvas'),
                context: null,
                sequenceFrames: [],
                length: 201,
                currentFrameIndex: -1,
                path: '',
                zeros: 5,
                urls: urlsA,
                preloadStep: 16,
                textSeqB: document.getElementById('text-seq-b'),
            },
            sequnceB: {
                id: 'sequnceB',
                canvas: document.getElementById('jpg-seq-b-canvas'),
                context: null,
                sequenceFrames: [],
                length: 169,
                currentFrameIndex: -1,
                path: '',
                zeros: 5,
                urls: urlsB,
                preloadStep: 16,
                loaded: false,
            },
            sequnceD: {
                id: 'sequnceD',
                canvas: document.getElementById('jpg-seq-d-canvas'),
                context: null,
                sequenceFrames: [],
                length: 119,
                currentFrameIndex: -1,
                path: '',
                zeros: 5,
                urls: urlsD,
                preloadStep: 16,
                loaded: false,
            },
            sequnceE: {
                id: 'sequnceD',
                canvas: document.getElementById('jpg-seq-e-canvas'),
                context: null,
                sequenceFrames: [],
                length: 225,
                currentFrameIndex: -1,
                path: '',
                zeros: 5,
                urls: urlsE,
                preloadStep: 16,
                loaded: false,
            },
            sequnceH: {
                id: 'sequnceh',
                canvas: document.getElementById('jpg-seq-h-canvas'),
                context: null,
                sequenceFrames: [],
                length: 132,
                currentFrameIndex: -1,
                path: '',
                zeros: 5,
                urls: urlsH,
                preloadStep: 16,
                loaded: false,
            },
        };

        this.sequences.sequnceA.context = this.sequences.sequnceA.canvas.getContext('2d');
        this.sequences.sequnceB.context = this.sequences.sequnceB.canvas.getContext('2d');
        this.sequences.sequnceD.context = this.sequences.sequnceD.canvas.getContext('2d');
        this.sequences.sequnceE.context = this.sequences.sequnceE.canvas.getContext('2d');
        this.sequences.sequnceH.context = this.sequences.sequnceH.canvas.getContext('2d');

        this.setCanvasSizes();
        this.initEvents();
        
        this.createSequenceFrames('sequnceA');
        this.createSequenceFrames('sequnceB');
        this.createSequenceFrames('sequnceD');
        this.createSequenceFrames('sequnceE');
        this.createSequenceFrames('sequnceH');
        
    }

    createSequenceFrames(seq){
        // Creates a 000 Number
        const zeroPad = (num, places) => String(num).padStart(places, '0');

        for (let i = 0; i <= this.sequences[seq].length; i++) {               
            this.sequences[seq].sequenceFrames[i] = {
                image: new Image(),
                isLoaded: false,
                src: this.sequences[seq].urls[this.sequences[seq].path+zeroPad(i, this.sequences[seq].zeros)]
            }
        }      
    }

    setCanvasSizes(){

        // A
        this.sequences.sequnceA.canvas.width = window.innerWidth;
        this.sequences.sequnceA.canvas.height = window.innerHeight;
        // B
        this.sequences.sequnceB.canvas.width = window.innerWidth;
        this.sequences.sequnceB.canvas.height = window.innerHeight;
        // D
        // 900 × 1080        
        this.sequences.sequnceD.canvas.width = (900/1080) * window.innerHeight;
        this.sequences.sequnceD.canvas.height = window.innerHeight;
        // E
        // 1920 × 1080        
        // Calc Canvas        
        setTimeout(() => {
            var rect = document.getElementById('js-seqe-canvas').getBoundingClientRect();
            this.sequences.sequnceE.canvas.height = rect.height;
            this.sequences.sequnceE.canvas.width = rect.width;
            // this.setProgress(this.sequences.sequnceE.currentFrameIndex, 'sequnceE');
        }, 250);

        
        // H
        this.sequences.sequnceH.canvas.width = window.innerWidth;
        this.sequences.sequnceH.canvas.height = window.innerHeight;
    }

    initEvents(){
        window.addEventListener('resize', this.setCanvasSizes.bind(this));
        window.addEventListener('resize', this.resizeProgress.bind(this));
    }

    loadSequence(seq, firstLoop = true){
        const imagesToLoad = [];
        const step = this.sequences[seq].preloadStep
        
        this.sequences[seq].sequenceFrames.forEach((f, i) => {
            if((firstLoop && i % step === 0)
            ||  (!firstLoop && i > 0 && i % step === 0 && i/2 % step !== 0)) {
                imagesToLoad.push(this.loadImage(f, i))
            }
        });

        Promise.all(imagesToLoad).then(() => {
            
            if(seq === 'sequnceA'){
                
                if(step > 1) {
                    if(this.sequences[seq].preloadStep == 16){
                        this.setProgress(0, seq);
                        addClass(this.sequences[seq].canvas, 'sequence-loaded');
                    }
                    this.sequences[seq].preloadStep = step/2
                    this.loadSequence('sequnceA', false);

                } else {
                    this.sequences.sequnceA.loaded = true;
                }
                    
            }
            if(seq === 'sequnceB'){
                if(step > 1) {
                    this.sequences[seq].preloadStep = step/2
                    this.loadSequence('sequnceB', false);
                } else {
                    this.setProgress(0, seq);
                    this.sequences.sequnceB.loaded = true;
                }
            }
            if(seq === 'sequnceD'){
                if(step > 1) {
                    this.sequences[seq].preloadStep = step/2
                    this.loadSequence('sequnceD', false);
                } else {
                    this.setProgress(0, seq);
                    this.sequences.sequnceD.loaded = true;
                }
            }
            if(seq === 'sequnceE'){
                if(step > 1) {
                    this.sequences[seq].preloadStep = step/2
                    this.loadSequence('sequnceE', false);
                } else {
                    this.setProgress(0, seq);
                    this.sequences.sequnceE.loaded = true;
                }
            }
            if(seq === 'sequnceH'){
                if(step > 1) {
                    this.sequences[seq].preloadStep = step/2
                    this.loadSequence('sequnceH', false);
                } else {
                    this.setProgress(0, seq);
                    this.sequences.sequnceH.loaded = true;
                    if(!isMobile()){
                        this.app.seqF.addSRC();
                    } 
                }
            }

        });

        
    }
    loadImage(frame, i){
        return new Promise(resolve => {
            // Set image source
            frame.image.src = frame.src;
            
            // Callback on image load
            const imageLoad = () => {
                // The Image is Loaded
                frame.isLoaded = true;

                // Remove the Listener
                frame.image.removeEventListener('load', imageLoad);
                
                // Promise resolve
                resolve();
            }
            frame.image.addEventListener('load', imageLoad);
        });
        
    }

    setProgress(frameIndex, seq){
        // if(frameIndex == this.sequences[seq].currentFrameIndex) {
        //     return;
        // }

        let frame = this.sequences[seq].sequenceFrames[frameIndex]

        if(frame.isLoaded) {
            const image = frame.image;
            const scale = Math.max(this.sequences[seq].canvas.width / image.width, this.sequences[seq].canvas.height / image.height);
            // Get the top left position of the image
            const x = (this.sequences[seq].canvas.width / 2) - (image.width / 2) * scale;
            const y = (this.sequences[seq].canvas.height / 2) - (image.height / 2) * scale;

            this.sequences[seq].context.drawImage(image, x, y, image.width * scale, image.height * scale);
            
        }

        this.sequences[seq].currentFrameIndex = frameIndex;    
    }
    setPrewProgress(image, seq){
        const scale = Math.max(this.sequences[seq].canvas.width / image.width, this.sequences[seq].canvas.height / image.height);
        // Get the top left position of the image
        const x = (this.sequences[seq].canvas.width / 2) - (image.width / 2) * scale;
        const y = (this.sequences[seq].canvas.height / 2) - (image.height / 2) * scale;
        this.sequences[seq].context.drawImage(image, x, y, image.width * scale, image.height * scale);
    }
    

    resizeProgress(){
        for (const [key, value] of Object.entries(this.sequences)) {
            if(value.loaded){
                this.setProgress(value.currentFrameIndex, key);
            }
        }
    }

}