const _temp0 = require("./00000.jpg");
const _temp1 = require("./00001.jpg");
const _temp2 = require("./00002.jpg");
const _temp3 = require("./00003.jpg");
const _temp4 = require("./00004.jpg");
const _temp5 = require("./00005.jpg");
const _temp6 = require("./00006.jpg");
const _temp7 = require("./00007.jpg");
const _temp8 = require("./00008.jpg");
const _temp9 = require("./00009.jpg");
const _temp10 = require("./00010.jpg");
const _temp11 = require("./00011.jpg");
const _temp12 = require("./00012.jpg");
const _temp13 = require("./00013.jpg");
const _temp14 = require("./00014.jpg");
const _temp15 = require("./00015.jpg");
const _temp16 = require("./00016.jpg");
const _temp17 = require("./00017.jpg");
const _temp18 = require("./00018.jpg");
const _temp19 = require("./00019.jpg");
const _temp20 = require("./00020.jpg");
const _temp21 = require("./00021.jpg");
const _temp22 = require("./00022.jpg");
const _temp23 = require("./00023.jpg");
const _temp24 = require("./00024.jpg");
const _temp25 = require("./00025.jpg");
const _temp26 = require("./00026.jpg");
const _temp27 = require("./00027.jpg");
const _temp28 = require("./00028.jpg");
const _temp29 = require("./00029.jpg");
const _temp30 = require("./00030.jpg");
const _temp31 = require("./00031.jpg");
const _temp32 = require("./00032.jpg");
const _temp33 = require("./00033.jpg");
const _temp34 = require("./00034.jpg");
const _temp35 = require("./00035.jpg");
const _temp36 = require("./00036.jpg");
const _temp37 = require("./00037.jpg");
const _temp38 = require("./00038.jpg");
const _temp39 = require("./00039.jpg");
const _temp40 = require("./00040.jpg");
const _temp41 = require("./00041.jpg");
const _temp42 = require("./00042.jpg");
const _temp43 = require("./00043.jpg");
const _temp44 = require("./00044.jpg");
const _temp45 = require("./00045.jpg");
const _temp46 = require("./00046.jpg");
const _temp47 = require("./00047.jpg");
const _temp48 = require("./00048.jpg");
const _temp49 = require("./00049.jpg");
const _temp50 = require("./00050.jpg");
const _temp51 = require("./00051.jpg");
const _temp52 = require("./00052.jpg");
const _temp53 = require("./00053.jpg");
const _temp54 = require("./00054.jpg");
const _temp55 = require("./00055.jpg");
const _temp56 = require("./00056.jpg");
const _temp57 = require("./00057.jpg");
const _temp58 = require("./00058.jpg");
const _temp59 = require("./00059.jpg");
const _temp60 = require("./00060.jpg");
const _temp61 = require("./00061.jpg");
const _temp62 = require("./00062.jpg");
const _temp63 = require("./00063.jpg");
const _temp64 = require("./00064.jpg");
const _temp65 = require("./00065.jpg");
const _temp66 = require("./00066.jpg");
const _temp67 = require("./00067.jpg");
const _temp68 = require("./00068.jpg");
const _temp69 = require("./00069.jpg");
const _temp70 = require("./00070.jpg");
const _temp71 = require("./00071.jpg");
const _temp72 = require("./00072.jpg");
const _temp73 = require("./00073.jpg");
const _temp74 = require("./00074.jpg");
const _temp75 = require("./00075.jpg");
const _temp76 = require("./00076.jpg");
const _temp77 = require("./00077.jpg");
const _temp78 = require("./00078.jpg");
const _temp79 = require("./00079.jpg");
const _temp80 = require("./00080.jpg");
const _temp81 = require("./00081.jpg");
const _temp82 = require("./00082.jpg");
const _temp83 = require("./00083.jpg");
const _temp84 = require("./00084.jpg");
const _temp85 = require("./00085.jpg");
const _temp86 = require("./00086.jpg");
const _temp87 = require("./00087.jpg");
const _temp88 = require("./00088.jpg");
const _temp89 = require("./00089.jpg");
const _temp90 = require("./00090.jpg");
const _temp91 = require("./00091.jpg");
const _temp92 = require("./00092.jpg");
const _temp93 = require("./00093.jpg");
const _temp94 = require("./00094.jpg");
const _temp95 = require("./00095.jpg");
const _temp96 = require("./00096.jpg");
const _temp97 = require("./00097.jpg");
const _temp98 = require("./00098.jpg");
const _temp99 = require("./00099.jpg");
const _temp100 = require("./00100.jpg");
const _temp101 = require("./00101.jpg");
const _temp102 = require("./00102.jpg");
const _temp103 = require("./00103.jpg");
const _temp104 = require("./00104.jpg");
const _temp105 = require("./00105.jpg");
const _temp106 = require("./00106.jpg");
const _temp107 = require("./00107.jpg");
const _temp108 = require("./00108.jpg");
const _temp109 = require("./00109.jpg");
const _temp110 = require("./00110.jpg");
const _temp111 = require("./00111.jpg");
const _temp112 = require("./00112.jpg");
const _temp113 = require("./00113.jpg");
const _temp114 = require("./00114.jpg");
const _temp115 = require("./00115.jpg");
const _temp116 = require("./00116.jpg");
const _temp117 = require("./00117.jpg");
const _temp118 = require("./00118.jpg");
const _temp119 = require("./00119.jpg");
const _temp120 = require("./00120.jpg");
const _temp121 = require("./00121.jpg");
const _temp122 = require("./00122.jpg");
const _temp123 = require("./00123.jpg");
const _temp124 = require("./00124.jpg");
const _temp125 = require("./00125.jpg");
const _temp126 = require("./00126.jpg");
const _temp127 = require("./00127.jpg");
const _temp128 = require("./00128.jpg");
const _temp129 = require("./00129.jpg");
const _temp130 = require("./00130.jpg");
const _temp131 = require("./00131.jpg");
const _temp132 = require("./00132.jpg");
const _temp133 = require("./00133.jpg");
const _temp134 = require("./00134.jpg");
const _temp135 = require("./00135.jpg");
const _temp136 = require("./00136.jpg");
const _temp137 = require("./00137.jpg");
const _temp138 = require("./00138.jpg");
const _temp139 = require("./00139.jpg");
const _temp140 = require("./00140.jpg");
const _temp141 = require("./00141.jpg");
const _temp142 = require("./00142.jpg");
const _temp143 = require("./00143.jpg");
const _temp144 = require("./00144.jpg");
const _temp145 = require("./00145.jpg");
const _temp146 = require("./00146.jpg");
const _temp147 = require("./00147.jpg");
const _temp148 = require("./00148.jpg");
const _temp149 = require("./00149.jpg");
const _temp150 = require("./00150.jpg");
const _temp151 = require("./00151.jpg");
const _temp152 = require("./00152.jpg");
const _temp153 = require("./00153.jpg");
const _temp154 = require("./00154.jpg");
const _temp155 = require("./00155.jpg");
const _temp156 = require("./00156.jpg");
const _temp157 = require("./00157.jpg");
const _temp158 = require("./00158.jpg");
const _temp159 = require("./00159.jpg");
const _temp160 = require("./00160.jpg");
const _temp161 = require("./00161.jpg");
const _temp162 = require("./00162.jpg");
const _temp163 = require("./00163.jpg");
const _temp164 = require("./00164.jpg");
const _temp165 = require("./00165.jpg");
const _temp166 = require("./00166.jpg");
const _temp167 = require("./00167.jpg");
const _temp168 = require("./00168.jpg");
const _temp169 = require("./00169.jpg");
module.exports = {
  "00000": _temp0,
  "00001": _temp1,
  "00002": _temp2,
  "00003": _temp3,
  "00004": _temp4,
  "00005": _temp5,
  "00006": _temp6,
  "00007": _temp7,
  "00008": _temp8,
  "00009": _temp9,
  "00010": _temp10,
  "00011": _temp11,
  "00012": _temp12,
  "00013": _temp13,
  "00014": _temp14,
  "00015": _temp15,
  "00016": _temp16,
  "00017": _temp17,
  "00018": _temp18,
  "00019": _temp19,
  "00020": _temp20,
  "00021": _temp21,
  "00022": _temp22,
  "00023": _temp23,
  "00024": _temp24,
  "00025": _temp25,
  "00026": _temp26,
  "00027": _temp27,
  "00028": _temp28,
  "00029": _temp29,
  "00030": _temp30,
  "00031": _temp31,
  "00032": _temp32,
  "00033": _temp33,
  "00034": _temp34,
  "00035": _temp35,
  "00036": _temp36,
  "00037": _temp37,
  "00038": _temp38,
  "00039": _temp39,
  "00040": _temp40,
  "00041": _temp41,
  "00042": _temp42,
  "00043": _temp43,
  "00044": _temp44,
  "00045": _temp45,
  "00046": _temp46,
  "00047": _temp47,
  "00048": _temp48,
  "00049": _temp49,
  "00050": _temp50,
  "00051": _temp51,
  "00052": _temp52,
  "00053": _temp53,
  "00054": _temp54,
  "00055": _temp55,
  "00056": _temp56,
  "00057": _temp57,
  "00058": _temp58,
  "00059": _temp59,
  "00060": _temp60,
  "00061": _temp61,
  "00062": _temp62,
  "00063": _temp63,
  "00064": _temp64,
  "00065": _temp65,
  "00066": _temp66,
  "00067": _temp67,
  "00068": _temp68,
  "00069": _temp69,
  "00070": _temp70,
  "00071": _temp71,
  "00072": _temp72,
  "00073": _temp73,
  "00074": _temp74,
  "00075": _temp75,
  "00076": _temp76,
  "00077": _temp77,
  "00078": _temp78,
  "00079": _temp79,
  "00080": _temp80,
  "00081": _temp81,
  "00082": _temp82,
  "00083": _temp83,
  "00084": _temp84,
  "00085": _temp85,
  "00086": _temp86,
  "00087": _temp87,
  "00088": _temp88,
  "00089": _temp89,
  "00090": _temp90,
  "00091": _temp91,
  "00092": _temp92,
  "00093": _temp93,
  "00094": _temp94,
  "00095": _temp95,
  "00096": _temp96,
  "00097": _temp97,
  "00098": _temp98,
  "00099": _temp99,
  "00100": _temp100,
  "00101": _temp101,
  "00102": _temp102,
  "00103": _temp103,
  "00104": _temp104,
  "00105": _temp105,
  "00106": _temp106,
  "00107": _temp107,
  "00108": _temp108,
  "00109": _temp109,
  "00110": _temp110,
  "00111": _temp111,
  "00112": _temp112,
  "00113": _temp113,
  "00114": _temp114,
  "00115": _temp115,
  "00116": _temp116,
  "00117": _temp117,
  "00118": _temp118,
  "00119": _temp119,
  "00120": _temp120,
  "00121": _temp121,
  "00122": _temp122,
  "00123": _temp123,
  "00124": _temp124,
  "00125": _temp125,
  "00126": _temp126,
  "00127": _temp127,
  "00128": _temp128,
  "00129": _temp129,
  "00130": _temp130,
  "00131": _temp131,
  "00132": _temp132,
  "00133": _temp133,
  "00134": _temp134,
  "00135": _temp135,
  "00136": _temp136,
  "00137": _temp137,
  "00138": _temp138,
  "00139": _temp139,
  "00140": _temp140,
  "00141": _temp141,
  "00142": _temp142,
  "00143": _temp143,
  "00144": _temp144,
  "00145": _temp145,
  "00146": _temp146,
  "00147": _temp147,
  "00148": _temp148,
  "00149": _temp149,
  "00150": _temp150,
  "00151": _temp151,
  "00152": _temp152,
  "00153": _temp153,
  "00154": _temp154,
  "00155": _temp155,
  "00156": _temp156,
  "00157": _temp157,
  "00158": _temp158,
  "00159": _temp159,
  "00160": _temp160,
  "00161": _temp161,
  "00162": _temp162,
  "00163": _temp163,
  "00164": _temp164,
  "00165": _temp165,
  "00166": _temp166,
  "00167": _temp167,
  "00168": _temp168,
  "00169": _temp169
}