/*
=====================================
    Section B
=====================================
*/

import { gsap, Power1 } from "gsap";

export class SeqB {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.content = this.app.content['seqb'];
        
        this.tlB = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.3,
                ease: Power1.easeOut,
                autoAlpha: 0,
                yPercent: 100,
                opacity: 0,
                blur: 8
            }            
        });

        this.tlB
            .from(".pc__seqb-t-a", {})
            .from(".pc__seqb-t-b", {}, '<+0.1')
            .from(".pc__seqb-t-c", {}, '<+0.1')

        this.played = false;

        this.createDOM();
        
    }

    createDOM(){
        for (let i = 0; i < this.content.length; i++) {
            document.getElementById(`js-seqb-t-${i}`).innerHTML = this.content[i];
        }
    }
    playTL(){
        if(this.played) return;
        this.tlB.play();
        this.played = true;
    }
    reverseTL(){
        if(!this.played) return;
        this.tlB.reverse();
        this.played = false;
    }

}