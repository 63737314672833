/*
=====================================
    Sidenav
=====================================
*/
import { gsap } from "gsap";

export class Sidenav {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.element = document.getElementsByClassName('js-side')[0];
        this.list = this.element.getElementsByClassName('js-side__list')[0];
        this.anchors = this.list.querySelectorAll('a[href^="#"]');
        
        this.tlDots = gsap.timeline({
            defaults: {
                duration: .3,
                ease: 'none'
            }
        });
        this.tlDots
            // Zwilling Enfinigy
            .from(".js-link-a", { autoAlpha: 0, x: 100, blur: 8, opacity: 0})
            .from(".js-link-b", { autoAlpha: 0, x: 20, blur: 8, opacity: 0}, '<+0.2')
            .from(".js-link-d", { autoAlpha: 0, x: 20, blur: 8, opacity: 0}, '<+0.2')
            .from(".js-link-f", { autoAlpha: 0, x: 20, blur: 8, opacity: 0}, '<+0.2')
            .from(".js-link-g", { autoAlpha: 0, x: 20, blur: 8, opacity: 0}, '<+0.2')

        this.tlDots.pause();

        this.tlItems = gsap.timeline({
            defaults: {
                duration: .2,
                ease: 'none'
            }
        });
        this.tlItems
            .from(".js-link-text-a", { autoAlpha: 0, y: 20, blur: 8, opacity: 0})
            .from(".js-link-text-b", { autoAlpha: 0, y: 20, blur: 8, opacity: 0}, '<+0.1')
            .from(".js-link-text-d", { autoAlpha: 0, y: 20, blur: 8, opacity: 0}, '<+0.1')
            .from(".js-link-text-f", { autoAlpha: 0, y: 20, blur: 8, opacity: 0}, '<+0.1')
            .from(".js-link-text-g", { autoAlpha: 0, y: 20, blur: 8, opacity: 0}, '<+0.1')
        
        this.tlItems.pause();        
    }

    displaySidenav(){
        this.tlDots.play();
        this.mouseHandlerInit();

        for (let i = 0; i < this.anchors.length; i++) {

            const that = this;
            
            this.anchors[i].addEventListener('click', function(event){
                event.preventDefault();
                var trigger = event.target.closest('.js-smooth-scroll'),
                    targetId = trigger.getAttribute('href').replace('#', ''),
				    target = document.getElementById(targetId);
                
                let linkST = that.scrolltrigger.create({
                    trigger: target,
                    start: "top top"
                });

                if(targetId == 'js-seq-f' || targetId == 'js-seq-g' || targetId == 'js-seq-j'){
                    gsap.to(window, {duration: 1, scrollTo: linkST.start});
                } else {
                    gsap.to(window, {duration: 1, scrollTo: linkST.start + 100});
                }
                
            });
        }

    }
    mouseHandlerInit(){
        this.element.addEventListener('mouseenter', this.onmouseenterSidenav.bind(this));
        this.element.addEventListener('mouseleave', this.onmouseleaveSidenav.bind(this));
    }
    onmouseenterSidenav(){
        this.tlItems.play();
    }
    onmouseleaveSidenav(){
        this.tlItems.reverse();
    }

}