/*
=====================================
    Section D
=====================================
*/
import {addClass, removeClass, isMobile}  from './helper.js';
import { gsap } from "gsap";

export class SeqD {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.content = this.app.content['seqd'];
        this.el = document.getElementById('js-seq-d');
        this.grid = document.getElementById('js-grid-d');
        
        this.preview = {
            image: new Image(),
            isLoaded: false,
            src: new URL("../../src/media/images/mobile/Boiler_PathTracer_9zu16_02.png", import.meta.url )
        }

        gsap.registerEffect({
            'name': 'scrollIND',
            effect: (targets, config) => {
                return gsap.from(targets, {
                    autoAlpha: 0,
                    y: 100,
                    opacity: 0,
                    blur: 8,
                    scrollTrigger: {
                        trigger: this.el,
                        start: config.start,
                        end: "bottom 100%",
                        scrub: 0.5,
                        // markers: true
                    }
                }); 
            }
        });
        

        gsap.registerEffect({
            'name': 'scrollOUTD',
            effect: (targets, config) => {
                return gsap.to(targets, {
                    y: -200,
                    opacity: 0,
                    blur: 8,
                    scrollTrigger: {
                        trigger: this.app.seqE.el,
                        start: config.start,
                        end: config.end,
                        scrub: 0.5,
                        // markers: true
                    }
                }); 
            }
        });


        this.tlD = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.20
            }
        });

        this.tlDimg;
        this.createDOM();
    }

    createDOM(){
        for (let i = 0; i < this.content.length; i++) {
            document.getElementById(`js-seqd-t-${i}`).innerHTML = this.content[i];
        }

        const imagesToLoad = [this.app.imgSequences.loadImage(this.preview, 0)];
        Promise.all(imagesToLoad).then(() => {

            if(!isMobile()){
                this.app.imgSequences.setPrewProgress(this.preview.image, 'sequnceD');
                this.app.imgSequences.loadSequence('sequnceD', true);
            } else {
                const mobileImg = `
                    <div class="pc__seqd-image">
                        <img src="${this.preview.image.src}">
                    </div>
                `;
                this.grid.insertAdjacentHTML('beforeend', mobileImg);
            }
        });
  

        if(isMobile()){
            // gsap.fromTo(".box", {opacity: 0}, {opacity: 0.5, duration: 1});
            this.tlD
                .fromTo(".pc__seqd-t-d", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
                .to(".pc__seqd-t-d", {yPercent: -100, autoAlpha: 0, opacity: 0})
                .fromTo(".pc__seqd-t-e", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
                .to(".pc__seqd-t-e", {yPercent: -100, autoAlpha: 0, opacity: 0})
                .fromTo(".pc__seqd-t-f", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
        }
    }

    runTLDimg(){
        const seq = this.app.imgSequences.sequences.sequnceD;
        this.tlDimg = gsap.timeline({
            defaults: {
                ease: 'none'
            },
            scrollTrigger: {
                trigger: this.el,
                start: "top top",
                end: "+=" + window.innerHeight * 2,
                scrub: 0.5,
                pin: true,
                onEnter: () => {
                    this.app.resetAnchors(document.getElementById('js-scroll-d'), true);
                },
                onLeaveBack: () => {
                    this.app.resetAnchors(document.getElementById('js-scroll-d'), false);
                },
                onUpdate: (self) => {
                    const prog = self.progress.toFixed(3);

                    if(!isMobile()){
                        if(prog < 0.300){
                            addClass(document.getElementById('js-seqd-t-3'), 'active-point');
                            removeClass(document.getElementById('js-seqd-t-4'), 'active-point');
                        }
                        if(prog > 0.300){
                            removeClass(document.getElementById('js-seqd-t-3'), 'active-point');
                            addClass(document.getElementById('js-seqd-t-4'), 'active-point');
                            removeClass(document.getElementById('js-seqd-t-5'), 'active-point');
                        }
                        if(prog > 0.600){                        
                            removeClass(document.getElementById('js-seqd-t-4'), 'active-point');
                            addClass(document.getElementById('js-seqd-t-5'), 'active-point');
                        }
                    } else {
                        const slideD = this.app.app.scaleValue(prog, [0.100, 1.000], [0.000, 1.000]);
                        this.tlD.seek(slideD); 
                    }

                }
            }
        });
        

        const sequenceProxy = {
            frame: 0
        }
        this.tlDimg
            .to(sequenceProxy, {
                frame: seq.length,
                snap: 'frame',
                duration: seq.length/20,
                onUpdate: () => {
                    if(this.app.imgSequences.sequences.sequnceD.loaded){
                        this.app.imgSequences.setProgress(sequenceProxy.frame, 'sequnceD');
                    }
                }
            })

        gsap.effects.scrollIND('.pc__seqd-t-a', {start: "top 70%"});
        gsap.effects.scrollIND('.pc__seqd-t-b', {start: "top 60%"});
        gsap.effects.scrollIND('.pc__seqd-t-c', {start: "top 50%"});
        gsap.effects.scrollOUTD('.pc__seqd-t-a-span', {start: "top bottom", end: "bottom 90%"});
        gsap.effects.scrollOUTD('.pc__seqd-t-b-span', {start: "top bottom", end: "bottom 90%"});
        gsap.effects.scrollOUTD('.pc__seqd-t-c-span', {start: "top bottom", end: "bottom 90%"});
        gsap.effects.scrollOUTD('.pc__seqd-t-f-span', {start: "top bottom", end: "bottom 90%"});
        



        
        if(!isMobile()){
            gsap.effects.scrollIND('.pc__seqd-t-d', {start: "top 40%"});
            gsap.effects.scrollIND('.pc__seqd-t-e', {start: "top 30%"});
            gsap.effects.scrollIND('.pc__seqd-t-f', {start: "top 20%"});
            gsap.effects.scrollOUTD('.pc__seqd-t-d-span', {start: "top bottom", end: "bottom 90%"});
            gsap.effects.scrollOUTD('.pc__seqd-t-e-span', {start: "top bottom", end: "bottom 90%"});
            gsap.effects.scrollOUTD('.pc__seqd-t-f-span', {start: "top bottom", end: "bottom 90%"});
        }

    }

}