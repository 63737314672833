/*
=====================================
    Drawer
=====================================
*/

import {addClass, removeClass}  from './helper.js';
export class Drawer {
    constructor(element){
        this.element = element;
        this.items = this.getChildrenByClassName(this.element, 'js-drawer__item');

        this.showClass = 'drawer__item--is-open';

        this.initAccordion();
        
    }

    initAccordion(){
        
        for (let i = 0; i < this.items.length; i++) {
            // const element = array[i];
            var button = this.items[i].getElementsByTagName('button')[0],
            content = this.items[i].getElementsByClassName('js-drawer__panel')[0],
            isOpen = this.items[i].classList.contains(this.showClass) ? 'true' : 'false';
            button.setAttribute('aria-expanded', isOpen);
            button.setAttribute('aria-controls', 'drawer-content-'+i);
            button.setAttribute('id', 'drawer-header-'+i);
            button.classList.add('js-drawer__trigger');
            content.setAttribute('aria-labelledby', 'drawer-header-'+i);
            content.setAttribute('id', 'drawer-content-'+i);
        }
        this.drawerEvents();
    }

    drawerEvents(){
        var self = this;
        this.element.addEventListener('click', function(event) {
            var trigger = event.target.closest('.js-drawer__trigger');
            //check index to make sure the click didn't happen inside a children accordion
            if( trigger && Array.prototype.indexOf.call(self.items, trigger.parentElement) >= 0) self.triggerAccordion(trigger);
        });
    }

    triggerAccordion(trigger){
        var bool = (trigger.getAttribute('aria-expanded') === 'true');
        this.animateDrawer(trigger, bool);

    }

    animateDrawer(trigger, bool){
        var self = this;
        var item = trigger.closest('.js-drawer__item'),
          content = item.getElementsByClassName('js-drawer__panel')[0],
          ariaValue = bool ? 'false' : 'true';

        if(!bool) addClass(item, this.showClass);
        trigger.setAttribute('aria-expanded', ariaValue);
        self.resetContentVisibility(item, content, bool);
        
    }
    resetContentVisibility (item, content, bool) {
        item.classList.toggle(this.showClass, !bool);
        content.removeAttribute("style");
    }
    closeSiblings(item){
        var index = Array.prototype.indexOf.call(this.items, item);
        for( var i = 0; i < this.items.length; i++) {
            if(this.items[i].classList.contains(this.showClass) && i != index) {
                removeClass(this.items[i], this.showClass);
                this.items[i].children[0].setAttribute('aria-expanded', false);
            }
        }
    }
    getChildrenByClassName(el, className) {
        var children = el.children,
        childrenByClass = [];
        for (var i = 0; i < children.length; i++) {
            if (children[i].classList.contains(className)) childrenByClass.push(children[i]);
        }
        return childrenByClass;
    }
}