/*
=====================================
    Section G
=====================================
*/
import {addClass, removeClass, isMobile}  from './helper.js';
import { gsap } from "gsap";


export class SeqG {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.content = app.content['seqg'];
        this.el = document.getElementById('js-seq-g');

        gsap.registerEffect({
            'name': 'scrollING',
            effect: (targets, config) => {
                return gsap.from(targets, {
                    autoAlpha: 0,
                    yPercent: 100,
                    opacity: 0,
                    blur: 8,
                    scrollTrigger: {
                        trigger: this.el,
                        start: config.start,
                        end: config.end,
                        scrub: 0.5,
                        // markers: true
                    }
                }); 
            }
        });

        this.tlKanne = gsap.timeline({
            paused: true,
            defaults: {
                duration: 1,
                autoAlpha: 0,
                yPercent: 100,
                opacity: 0
            }
        });


        this.tlG = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.20
            }
        });
            
        this.tlGimg;
        this.tlGtrigger;

        this.imageContainer = document.getElementById('js-seqg-img-container');
        this.images = [
            new URL("../../src/media/Kanne_Split_5/Kanne_Split_A.png", import.meta.url ),
            new URL("../../src/media/Kanne_Split_5/Kanne_Split_C.png", import.meta.url ),
            new URL("../../src/media/Kanne_Split_5/Kanne_Split_B.png", import.meta.url )  
        ];

        this.createDOM();
    }

    createDOM(){
        for (let i = 0; i < this.content.length; i++) {
            document.getElementById(`js-seqg-t-${i}`).innerHTML = this.content[i];
        }

        for (let j = 0; j < this.images.length; j++) {
            const image = `
                <div class="pc__seqg-image pc__seqg-I-${j} v-hidden">
                    <img id="js-seqg-img-${j}" class=" loadImg" src="${this.images[j]}" alt="Die Kaffeekanne"/>
                </div>
            `;
            this.imageContainer.insertAdjacentHTML('beforeend', image);
            const imageToLoad = this.app.loadImage(document.getElementById(`js-seqg-img-${j}`));
            Promise.all([imageToLoad]).then((data) => {
                addClass(data[0], 'loaded');
            });
        }

        this.tlKanne
            .from(".pc__seqg-I-0", {})
            .from(".pc__seqg-I-1", {yPercent: 200}, '<')
            .from(".pc__seqg-I-2", {yPercent: 300}, '<')


        if(isMobile()){
            this.tlG
                .fromTo(".pc__seqg-t-c", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
                .to(".pc__seqg-t-c", {yPercent: -100, autoAlpha: 0, opacity: 0})
                .fromTo(".pc__seqg-t-d", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
                .to(".pc__seqg-t-d", {yPercent: -100, autoAlpha: 0, opacity: 0})
                .fromTo(".pc__seqg-t-e", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
        }            
        
    }

    runTLGimg(){

        if(!isMobile()){
            this.tlGimg = gsap.timeline({
                scrollTrigger: {
                    trigger: this.el,
                    scrub: 0.5,
                    start: "top 60%",
                    end: 'bottom top',
                    scrub: 0.5,
                    onEnter: () => {
                        this.app.resetAnchors(document.getElementById('js-scroll-g'), true);
                    },
                    onLeaveBack: () =>{
                        // this.app.resetAnchors(document.getElementById('js-scroll-f'), true);
                        this.app.resetAnchors(document.getElementById('js-scroll-d'), false);
                    },
                    onLeave: () =>{
                        this.app.resetAnchors(document.getElementById('js-scroll-d'), false);
                    },
                    onUpdate: (self) => {
                        const prog = self.progress.toFixed(3);
                        const runKanne = this.app.app.scaleValue(prog, [0.100, 0.500], [0.0, 1.0]);

                        this.tlKanne.seek(runKanne);

                        if(prog < 0.200){
                            addClass(document.getElementById('js-seqg-t-2'), 'active-point');
                            removeClass(document.getElementById('js-seqg-t-3'), 'active-point');
                        }
                        if(prog > 0.300){
                            removeClass(document.getElementById('js-seqg-t-2'), 'active-point');
                            addClass(document.getElementById('js-seqg-t-3'), 'active-point');
                            removeClass(document.getElementById('js-seqg-t-4'), 'active-point');
                        }

                        if(prog > 0.400){                        
                            removeClass(document.getElementById('js-seqg-t-3'), 'active-point');
                            addClass(document.getElementById('js-seqg-t-4'), 'active-point');
                        }
        
                    }
                }
            });
        } else {
            this.tlGimg = gsap.timeline({
                scrollTrigger: {
                    trigger: this.el,
                    scrub: 0.5,
                    start: "top top",
                    end: "+=" + window.innerHeight * 3,
                    scrub: 0.5,
                    pin: true,
                    onUpdate: (self) => {
                        const prog = self.progress.toFixed(3);
                        const runKanne = this.app.app.scaleValue(prog, [0.000, 0.400], [0.0, 1.0]);

                        this.tlKanne.seek(runKanne);
                        const slideG = this.app.app.scaleValue(prog, [0.250, 0.650], [0.000, 1.000]);
                        this.tlG.seek(slideG); 

        
                    }
                }
            });
        }


        gsap.effects.scrollING('.pc__seqg-t-a', {start: "top 50%", end: "top 20%"});
        gsap.effects.scrollING('.pc__seqg-t-b', {start: "top 40%", end: "top 10%"});
        if(!isMobile()){
            gsap.effects.scrollING('.pc__seqg-t-c', {start: "top 30%", end: "top top"});
            gsap.effects.scrollING('.pc__seqg-t-d', {start: "top 20%", end: "top top"});
            gsap.effects.scrollING('.pc__seqg-t-e', {start: "top 10%", end: "top top"});
        }

        this.scrolltrigger.refresh();
    }
}